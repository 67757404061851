/* eslint-disable valid-jsdoc */
import {printKOT, printBill, setLoading} from '../miscActions';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {toast} from 'react-toastify';

export const setCurrentSelectedOrder = (id, type) => ({
  type: 'SET_CURRENT_SELECTED_ORDER',
  payload: {_id: id, type: type},
});

export const setOrderLoading = (status) => ({
  type: 'ORDER_LOADING',
  payload: status,
});

export const saveOrders = (order) => {
  return (dispatch) => {
    switch (order.__type) {
      case 'delivery':
        if (order.curr_state === 'delivery-not-possible') {
          dispatch(removeOrders(order));
          dispatch({type: 'ADD_TO_NOT_POSSIBLE_DELIVERY', payload: order});
        } else {
          dispatch({type: 'ADD_TO_DELIVERY_ORDERS', payload: order});
        }
        break;
      case 'qsr':
        dispatch({type: 'ADD_TO_QSR_ORDERS', payload: order});
        break;
      default:
        break;
    }
  };
};

export const removeOrders = (order) => {
  return (dispatch) => {
    switch (order.__type) {
      case 'delivery':
        dispatch({type: 'REMOVE_DELIVERY_ORDERS', payload: order._id});
        break;
      case 'qsr':
        dispatch({type: 'REMOVE_QSR_ORDERS', payload: order._id});
        break;
      default:
        break;
    }
  };
};

export const getOrders = () => {
  return (dispatch, getState) => {
    dispatch({type: 'RESET_ORDERS'});
    const id = getState().auth.restData.store_id;
    dispatch(setOrderLoading(true));
    http
      .get(`${API_URL}/orders/store/${id}`)
      .then(async (res) => {
        if (res.data.length > 0) {
          res.data.forEach((order) => {
            if (
              order.active &&
              (!order.canceled || order.__type === 'online')
            ) {
              dispatch(saveOrders(order));
            }
          });
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};


export const addDiscount = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}`, {discount: data})
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const payOrder = (id, mode, walletType, settle, partPaymnet) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const data = {};
    data['payment_mode'] = mode;
    if (walletType && walletType !== '') {
      data['wallet'] = walletType;
    }
    // console.log('partPayement', partPaymnet, typeof partPaymnet);
    if (typeof partPaymnet === 'string' || typeof partPaymnet === 'number') {
      data['amount'] = parseInt(partPaymnet);
    }
    http
      .post(`${API_URL}/orders/${id}/pay`, data)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
        if (settle && res.data.paid) {
          await dispatch(settleOrder(id));
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const localLastMileSupport = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/local_lastmile/${orderID}/searchRiders`)
      .then(async (res) => {
        toast.success(
          "Looking for local runner to fulfill your delivery. We'll notify you when a rider accepts"
        );
        await dispatch(saveOrders(res.data.newOrder, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const capturePayment = (id, printKot, settle) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/capture`)
      .then(async (res) => {
        if (printKot) {
          await dispatch(printKOTandBillOrder(res.data));
          await dispatch(removeOrders(res.data));
        } else {
          await dispatch(printOrderBill(res.data));
        }
        await dispatch(setOrderLoading(false));
        if (settle) {
          await dispatch(settleOrder(id));
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const settleOrder = (id) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${id}/settle`)
      .then(async (res) => {
        await dispatch(removeOrders(res.data));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const deliverySupport = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/initExtDelivery`)
      .then(async (res) => {
        toast.success(
          "Looking for runner to fulfill your delivery. We'll notify you when a runner is assigned"
        );
        await dispatch(saveOrders(res.data, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const lastMiletoSelf = (orderID) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/initSelfDelivery`)
      .then(async (res) => {
        await dispatch(saveOrders(res.data, true));
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY',
          payload: orderID,
        });
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelOrder = (orderID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteOrder = {
      canceled: true,
      reason: reason,
    };
    http
      .delete(`${API_URL}/orders/${orderID}`, {data: deleteOrder})
      .then(async (res) => {
        await dispatch(removeOrders(res.data));
        await dispatch({
          type: 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY',
          payload: orderID,
        });
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const updateOrder = (id, loading) => {
  return (dispatch) => {
    if (loading) {
      dispatch(setOrderLoading(true));
    }
    http
      .get(`${API_URL}/orders/${id}`)
      .then(async (res) => {
        if (res.data.active && !res.data.canceled) {
          await dispatch(saveOrders(res.data));
          // if (res.data.bill_print) {
          //   await dispatch(printOrderBill(res.data));
          // }
        }
        if (res.data.settled || res.data.canceled) {
          await dispatch(removeOrders(res.data));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch(() => {
        dispatch(setOrderLoading(false));
      });
  };
};

export const acknowledgeOrder = (id) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .put(`${API_URL}/orders/${id}/ack`)
      .then(async (res) => {
        if (res.data.active) {
          await dispatch(saveOrders(res.data, true));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const deliveryOrder = (id, data) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    http
      .post(`${API_URL}/delivery/${id}/state`, data)
      .then(async (res) => {
        if (res.data.updatedOrder) {
          await dispatch(saveOrders(res.data.updatedOrder));
        }
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const cancelKOTItem = (orderID, kotID, itemID, reason) => {
  return (dispatch) => {
    dispatch(setOrderLoading(true));
    const deleteItem = {
      kots: [
        {
          _id: kotID,
          items: [
            {
              _id: itemID,
              canceled: true,
            },
          ],
        },
      ],
      reason: reason,
    };
    http
      .delete(`${API_URL}/orders/${orderID}`, {data: deleteItem})
      .then(async (res) => {
        await dispatch(saveOrders(res.data.updatedOrder, true));
        await dispatch(setOrderLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message)
          toast.error(err.response.data.message);
        dispatch(setOrderLoading(false));
      });
  };
};

export const printOrderBill = (order) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    console.log('GSTIN', GSTIN);
    if (!order.GSTIN && GSTIN && GSTIN.length > 1) {
      dispatch(splitGSTBill(order._id));
    } else {
      const billItems = [];
      const partition =
        order.split_bill && order.split_bill.partition
          ? order.split_bill.partition
          : 1;
      if (order.bill.kots && order.bill.kots.length > 0) {
        order.bill.kots.forEach((kot) => {
          kot.items &&
            kot.items.length > 0 &&
            kot.items.forEach((item) => {
              const temp = {};
              let itemBasePrice = 0;
              temp['item_name'] = item.name;
              itemBasePrice = item.item_base_price / partition;
              temp['net_amount'] = (item.net_amount / partition).toFixed(2);
              temp['addons'] = item.addons;
              if (item.addons.length > 0) {
                item.addons.forEach((addons) => {
                  itemBasePrice += addons.total_amount / partition;
                });
              }
              temp['base_price'] = itemBasePrice.toFixed(2);
              temp['qty'] = item.qty;
              temp['note'] = item.note;
              temp['kitchen_no'] = item.kitchen_number;
              temp['cancelled'] = item.canceled;
              temp['cancelled_print'] = item.canceled_print;
              billItems.push(temp);
            });
        });
      }
      const name = getState().auth.restaurant.name;
      const addressObject = getState().auth.restaurant.address;
      const address =
        addressObject &&
        `${addressObject.line1}, ${addressObject.line2}, ${addressObject.city.name} - ${addressObject.pincode}`;
      let gstIN = '';
      if (order.GSTIN) {
        gstIN = order.GSTIN;
      } else if (GSTIN && GSTIN.length > 0 && GSTIN[0].GSTIN) {
        gstIN = GSTIN[0].GSTIN;
      }
      const billData = {
        restaurant_name: name,
        gst_no: gstIN,
        restaurant_address: address,
        table_no: order.__type.toUpperCase(),
        bill_no: order.order_number,
        bill_data: billItems,
        sub_total: `${(order.bill.sub_total_amount / partition).toFixed(2)}`,
        taxes: [],
        charges: [],
        discount: order.bill.total_discount / partition,
        grand_total: `${(order.bill.round_off_total_amount / partition).toFixed(
          2
        )}`,
        currency: order.bill.currency && order.bill.currency.symbol,
        timestamp: order.createdAt,
      };
      if (order.bill.taxes && order.bill.taxes.length > 0) {
        const tempTax = [];
        order.bill.taxes.forEach((tax) => {
          tempTax.push({
            amount: (tax.amount / partition).toFixed(2),
            name: {...tax.name},
            value: (tax.value / partition).toFixed(2),
            _id: tax._id,
          });
        });
        billData['taxes'] = tempTax;
      }
      if (order.bill.charges && order.bill.charges.length > 0) {
        const tempCharge = [];
        order.bill.charges.forEach((charge) => {
          tempCharge.push({
            amount: (charge.amount / partition).toFixed(2),
            name: charge.name,
            _id: charge._id,
          });
        });
        billData['charges'] = tempCharge;
      }
      if (order.tables && order.tables.length > 0 && order.tables[0].table_no) {
        const temp = [];
        order.tables.forEach((table) => {
          temp.push(table.table_no);
        });
        billData['table_no'] =
          partition > 1 ? `${temp.join(', ')} (split)` : temp.join(', ');
      }
      dispatch(printBill(billData, order._id, partition));
    }
  };
};

export const splitGSTBill = (orderID, kotPrint, orderFor) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/orders/${orderID}/split_bill/gst`)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.forEach((order, index) => {
            if (kotPrint) {
              dispatch(
                printTakeawayBill(
                  {...order, _id: `${order._id}-${index}`},
                  orderFor
                )
              );
            } else {
              dispatch(
                printOrderBill({...order, _id: `${order._id}-${index}`})
              );
            }
          });
        }
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const printKOTandBillOrder = (order, printFor) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    const billItems = [];
    order.bill.kots &&
      order.bill.kots.length > 0 &&
      order.bill.kots.forEach((kot) => {
        const filteredItemData = [];
        kot.items &&
          kot.items.length > 0 &&
          kot.items.forEach((item) => {
            const temp = {};
            temp['item_name'] = item.name;
            temp['base_price'] = item.item_base_price;
            temp['net_amount'] = item.net_amount;
            temp['addons'] = item.addons;
            temp['qty'] = item.qty;
            temp['note'] = item.note;
            temp['kitchen_no'] = item.kitchen_number;
            temp['cancelled'] = item.canceled;
            temp['cancelled_print'] = item.canceled_print;
            billItems.push(temp);
            filteredItemData.push(temp);
          });
        if (!kot.printed) {
          if (order.tables && order.tables.length > 0) {
            dispatch(
              printKOT(
                order.tables[0].table_no,
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          } else if (printFor) {
            dispatch(
              printKOT(
                `${order.__type.toUpperCase()} - ${printFor}`,
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          } else {
            dispatch(
              printKOT(
                order.__type.toUpperCase(),
                filteredItemData,
                kot.daily_number,
                kot.canceled,
                order._id,
                kot._id,
                kot.createdAt
              )
            );
          }
        }
      });
    if (!order.GSTIN && GSTIN && GSTIN.length > 1) {
      dispatch(splitGSTBill(order._id, true, printFor));
    } else {
      dispatch(printTakeawayBill(order, printFor));
    }
  };
};

export const printTakeawayBill = (order, printFor) => {
  return (dispatch, getState) => {
    const GSTIN =
      getState().auth.restaurant &&
      getState().auth.restaurant.preferences &&
      getState().auth.restaurant.preferences.GSTIN;
    const name = getState().auth.restaurant.name;
    const addressObject = getState().auth.restaurant.address;
    const address =
      addressObject &&
      `${addressObject.line1}, ${addressObject.line2}, ${addressObject.city.name} - ${addressObject.pincode}`;
    let gstIN = '';
    if (order.GSTIN) {
      gstIN = order.GSTIN;
    } else if (GSTIN && GSTIN.length > 0 && GSTIN[0].GSTIN) {
      gstIN = GSTIN[0].GSTIN;
    }
    const billItems = [];
    if (order.bill.kots && order.bill.kots.length > 0) {
      order.bill.kots.forEach((kot) => {
        kot.items &&
          kot.items.length > 0 &&
          kot.items.forEach((item) => {
            const temp = {};
            let itemBasePrice = 0;
            temp['item_name'] = item.name;
            itemBasePrice = item.item_base_price;
            temp['net_amount'] = item.net_amount.toFixed(2);
            temp['addons'] = item.addons;
            if (item.addons.length > 0) {
              item.addons.forEach((addons) => {
                itemBasePrice += addons.total_amount;
              });
            }
            temp['base_price'] = itemBasePrice.toFixed(2);
            temp['qty'] = item.qty;
            temp['note'] = item.note;
            temp['kitchen_no'] = item.kitchen_number;
            temp['cancelled'] = item.canceled;
            temp['cancelled_print'] = item.canceled_print;
            billItems.push(temp);
          });
      });
    }
    const billData = {
      restaurant_name: name,
      gst_no: gstIN,
      restaurant_address: address,
      table_no: `${order.__type.toUpperCase()}${
        printFor ? ` - ${printFor}` : ''
      }`,
      bill_no: order.order_number,
      bill_data: billItems,
      sub_total:
        typeof order.bill.sub_total_amount === 'string'
          ? order.bill.sub_total_amount
          : order.bill.sub_total_amount.toFixed(2),
      taxes: order.bill.taxes,
      charges: order.bill.charges,
      discount: order.bill.total_discount,
      grand_total: order.bill.round_off_total_amount.toFixed(2),
      currency: order.bill.currency && order.bill.currency.symbol,
      timestamp: order.createdAt,
    };
    if (order.tables && order.tables.length > 0 && order.tables[0].table_no) {
      billData['table_no'] = order.tables[0].table_no;
    }
    dispatch(printBill(billData, order._id));
  };
};
