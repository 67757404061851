import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import {useStyles} from '../styles';
import {useRouteMatch} from 'react-router';
import MenuSelection from '../../MenuSelection';
import DashboardIcon from '../svgs/DashboardIcon';
import ManageItemsIcon from '../svgs/ManageItemsIcon';
import LogoutIcon from '../svgs/LogoutIcon';
import {useDispatch, useSelector} from 'react-redux';
import {push} from 'connected-react-router';
import {logout} from '../../../services/actions/authActions';
import RestInfo from './RestInfo';
import SalesReportIcon from '../svgs/SalesReportIcon';
import {useTranslation} from 'react-i18next';
import LangSelector from './LangSelector';
import ProfileIcon from '@material-ui/icons/AccountCircleRounded';

function MenuDrawer({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  const menus = [
    {
      label: t('Dashboard'),
      active: useRouteMatch('/dashboard') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/dashboard'));
      },
      icon: <DashboardIcon />,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      label: t('Manage Items'),
      active: useRouteMatch('/manage-items') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/manage-items'));
      },
      icon: <ManageItemsIcon />,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      label: t('Sales report'),
      active: useRouteMatch('/sales-report') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/sales-report'));
      },
      icon: <SalesReportIcon />,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      label: t('Profile'),
      active: useRouteMatch('/profile'),
      handleMenu: () => {
        handleClose();
        dispatch(push('/profile'));
      },
      icon: <ProfileIcon />,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      label: t('Logout'),
      active: false,
      handleMenu: () => {
        handleClose();
        dispatch(logout());
      },
      icon: <LogoutIcon />,
      visible: true,
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <RestInfo />
        <div className={classes.menuList}>
          {menus
            .filter((a) => a.visible)
            .map((menu, index) => (
              <MenuSelection
                key={index}
                active={menu.active}
                label={menu.label}
                handleClick={menu.handleMenu}
              >
                {menu.icon}
              </MenuSelection>
            ))}
        </div>
        <div className={classes.bottomArea}>
          <LangSelector />
          <div className={classes.version}>
            V-{window.localStorage.getItem('appVersion')}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
