import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ablyAck} from './actions/miscActions';
import {
  updateOrder,
  getOrders,
} from './actions/orderActions';

function Ably() {
  const dispatch = useDispatch();
  const restID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  /** connect to restaurant channel when restID updated */
  useEffect(() => {
    if (restID && dashboardAccess && window.restaurantChannel) {
      const orderListener = (message) => {
        console.log('order---->', message.data);
        if (message.data.data && message.data.data.order_id) {
          dispatch(updateOrder(message.data.data.order_id));
        }
        dispatch(ablyAck(message.data.ably_id));
      };
      window.restaurantChannel.attach();
      window.restaurantChannel.once('attached', () => {
        console.log('%cably restaurant channel connected', 'color: green;');
          dispatch(getOrders());
          window.restaurantChannel.subscribe('order', orderListener);
      });
    } else if (window.restaurantChannel && window.restaurantChannel.name) {
      window.restaurantChannel.detach();
      window.restaurantChannel.unsubscribe();
      window.restaurantChannel = null;
    }
  }, [restID, dashboardAccess, dispatch]);

  return <></>;
}

export default Ably;
