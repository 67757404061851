import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  capturePayment,
  cancelOrder,
  acknowledgeOrder,
  lastMiletoSelf,
} from '../../services/actions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from './styles';
import {useTranslation} from 'react-i18next';

function Notification({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const paymentCaptureList = useSelector((state) => {
    const temp = [];
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((qsr) => {
        if (
          qsr.curr_state === 'payment-initalized' &&
          qsr.initialized_by === 'user'
        ) {
          temp.push({
            _id: qsr._id,
            type: qsr.__type,
            orderNo: qsr.order_number,
            total: qsr.bill.round_off_total_amount,
            mode: qsr.payment && qsr.payment.mode,
          });
        }
      });
    }
    return temp;
  });

  const deliveryNPOrders = useSelector((state) => {
    const temp = [];
    if (state.orders.deliveryNPOrders.length > 0) {
      state.orders.deliveryNPOrders.forEach((delivery) => {
        temp.push({
          _id: delivery._id,
          items: delivery.bill.kots[0].items,
        });
      });
    }
    return temp;
  });
  
  const deliveryAcceptList = useSelector((state) => {
    const temp = [];
    if (state.orders.deliveryOrders.length > 0) {
      state.orders.deliveryOrders.forEach((delivery) => {
        if (delivery.next_states[0] === 'acknowledged') {
          temp.push({
            _id: delivery._id,
            items: delivery.bill.kots[0].items,
            type: delivery.__type,
            scheduled_delivery_time: delivery.scheduled_delivery_time
              ? delivery.scheduled_delivery_time
              : null,
          });
        }
      });
    }
    return temp;
  });

  const qsrAcceptList = useSelector((state) => {
    const temp = [];
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((delivery) => {
        if (delivery.next_states[0] === 'acknowledged') {
          temp.push({
            _id: delivery._id,
            items: delivery.bill.kots[0].items,
            type: delivery.__type,
          });
        }
      });
    }
    return temp;
  });

  const notificationUI = () => {
    return (
      <div className={classes.notificationUIRoot}>
        <div className={classes.notificationTitle}>
          <IconButton className={classes.iconButton}>
            <CloseIcon color={'primary'} onClick={handleClose} />
          </IconButton>
          <div>{t('Notifications')}</div>
        </div>
        {deliveryNPOrders &&
          deliveryNPOrders.length > 0 &&
          deliveryNPOrders.map((order, index) => (
            <div
              className={classes.queueStyle}
              key={index}
              style={{
                maxWidth: '250px',
                textAlign: 'center',
              }}
            >
              <strong>All things come for those who wait!</strong>
              <div
                style={{
                  marginTop: 6,
                  fontSize: 13,
                }}
              >
                No runners available near you. But if you try after 15mins, you
                might find a runner and can have a happy customer.
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 5,
                    borderBottom: '1px solid lightgray',
                    textTransform: 'uppercase',
                  }}
                >
                  <div>{t('item')}</div>
                  <div>{t('quantity')}</div>
                </div>
                {order.items &&
                  order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
              </div>
              <Button
                style={{fontSize: 12, marginTop: 12}}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(lastMiletoSelf(order._id));
                  handleClose();
                }}
              >
                Wait and delight your customer
              </Button>
              <Button
                style={{marginTop: 12, fontSize: 12}}
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  dispatch(
                    cancelOrder(
                      order._id,
                      'Delivery support not available at this moment'
                    )
                  );
                  handleClose();
                }}
              >
                {t('Cancel')}
              </Button>
            </div>
          ))}
        {paymentCaptureList.length > 0 &&
          paymentCaptureList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div className={classes.flexBox}>
                <div style={{width: '350px'}}>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Order No')} : </strong>
                    <span style={{color: 'rgb(253, 42, 42)'}}>
                      {order.orderNo}
                    </span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Payment method')} : </strong>
                    <span>{order.mode}</span>
                  </Typography>
                  <Typography
                    style={{textAlign: 'center', textTransform: 'uppercase'}}
                  >
                    <strong>{t('Total')} : </strong>
                    <span>{order.total}</span>
                  </Typography>
                  <div className={classes.flexBox}>
                    <Button
                      style={{marginRight: 5}}
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(capturePayment(order._id, true));
                        handleClose();
                      }}
                    >
                      {t('Accept')}
                    </Button>
                    <Button
                      style={{marginLeft: 5}}
                      variant="outlined"
                      color="primary"
                      fullWidth
                      onClick={() => {
                        dispatch(
                          cancelOrder(
                            order._id,
                            "Store doesn't accept this payment"
                          )
                        );
                        handleClose();
                      }}
                    >
                      {t('Reject')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {qsrAcceptList.length > 0 &&
          qsrAcceptList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div style={{width: '250px'}}>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <strong>{t('new takeaway order arrived')}</strong>
                </Typography>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 5,
                      borderBottom: '1px solid lightgray',
                      textTransform: 'uppercase',
                    }}
                  >
                    <div>{t('item')}</div>
                    <div>{t('quantity')}</div>
                  </div>
                  {order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
                </div>
                <div className={classes.flexBox} style={{marginTop: 5}}>
                  <Button
                    style={{marginRight: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(acknowledgeOrder(order._id));
                      handleClose();
                    }}
                  >
                    {t('Accept')}
                  </Button>
                  <Button
                    style={{marginLeft: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(
                        cancelOrder(
                          order._id,
                          "Store doesn't accept your order"
                        )
                      );
                      handleClose();
                    }}
                  >
                    {t('Reject')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        {deliveryAcceptList.length > 0 &&
          deliveryAcceptList.map((order, index) => (
            <div className={classes.queueStyle} key={index}>
              <div style={{width: '250px'}}>
                <Typography
                  style={{
                    fontSize: 14,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <strong>{t('new delivery order arrived')}</strong>
                </Typography>
                {order.scheduled_delivery_time && (
                  <Typography
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                      marginTop: 6,
                    }}
                  >
                    <p>
                      Order scheduled @{' '}
                      {new Date(order.scheduled_delivery_time).toLocaleString()}
                    </p>
                  </Typography>
                )}
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: 5,
                      borderBottom: '1px solid lightgray',
                      textTransform: 'uppercase',
                    }}
                  >
                    <div>{t('item')}</div>
                    <div>{t('quantity')}</div>
                  </div>
                  {order.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 5,
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      <div>
                        {
                          item.name[
                            language &&
                            language.includes(
                              window.localStorage.i18nextLng.split('-')[0]
                            )
                              ? window.localStorage.i18nextLng.split('-')[0]
                              : 'en'
                          ]
                        }
                      </div>
                      <div>{item.qty}</div>
                    </div>
                  ))}
                </div>
                <div className={classes.flexBox} style={{marginTop: 5}}>
                  <Button
                    style={{marginRight: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(acknowledgeOrder(order._id));
                      handleClose();
                    }}
                  >
                    {t('Accept')}
                  </Button>
                  <Button
                    style={{marginLeft: 5}}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      dispatch(
                        cancelOrder(
                          order._id,
                          "Store doesn't accept your order"
                        )
                      );
                      handleClose();
                    }}
                  >
                    {t('Reject')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <Drawer anchor={'right'} open={open} onClose={handleClose}>
      {notificationUI()}
    </Drawer>
  );
}

export default Notification;
