import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function RefreshIcon() {
  return (
    <SvgIcon
      style={{fontSize: 20}}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.9542 17.1434C23.7955 16.6548 23.2707 16.3877 22.7819 16.5461L21.5721 16.9392C22.3132 15.4065 22.7014 13.7211 22.7014 12.0001C22.7014 5.74124 17.6096 0.649353 11.3507 0.649353C5.09189 0.649353 0 5.74124 0 12.0001C0 18.2589 5.09189 23.3508 11.3507 23.3508C11.8645 23.3508 12.2811 22.9342 12.2811 22.4204C12.2811 21.9067 11.8645 21.49 11.3507 21.49C6.11792 21.49 1.86077 17.2329 1.86077 12.0001C1.86077 6.76727 6.11792 2.51012 11.3507 2.51012C16.5836 2.51012 20.8406 6.76727 20.8406 12.0001C20.8406 13.4419 20.5153 14.8535 19.8937 16.136L19.4803 14.8636C19.3216 14.375 18.7966 14.1076 18.3081 14.2663C17.8193 14.4251 17.5518 14.95 17.7106 15.4386L18.8077 18.8154C18.9354 19.2086 19.3001 19.4586 19.6923 19.4586C19.7875 19.4586 19.8845 19.4439 19.98 19.4129L23.3568 18.316C23.8455 18.1571 24.113 17.6323 23.9542 17.1434Z" />
    </SvgIcon>
  );
}

export default RefreshIcon;
