import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import miscReducers from './miscReducers';
import prefReducers from './prefReducers';
import itemReducers from './itemReducers';
import orderReducers from './orderReducers';
import salesReducers from './salesReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    misc: miscReducers,
    pref: prefReducers,
    item: itemReducers,
    orders: orderReducers,
    sales: salesReducers,
  });

export default reducers;
