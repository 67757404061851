import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationDE from './locales/de/translation.json';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationET from './locales/et/translation.json';
import translationFIL from './locales/fil/translation.json';
import translationJA from './locales/ja/translation.json';
import translationMS from './locales/ms/translation.json';
import translationRU from './locales/ru/translation.json';
import translationTH from './locales/th/translation.json';
import translationZH from './locales/zh/translation.json';

i18n.use(LanguageDetector).init({
  resources: {
    de: {
      translations: translationDE,
    },
    en: {
      translations: translationEN,
    },
    es: {
      translations: translationES,
    },
    et: {
      translations: translationET,
    },
    fil: {
      translations: translationFIL,
    },
    ja: {
      translations: translationJA,
    },
    ms: {
      translations: translationMS,
    },
    ru: {
      translations: translationRU,
    },
    th: {
      translations: translationTH,
    },
    zh: {
      translations: translationZH,
    },
  },
  fallbackLng: 'en',
  debug: false,
  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
