import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import MenuIcon from './svgs/MenuIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuDrawer from './components/MenuDrawer';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import HotMenu from './components/HotMenu';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OrderHeading from '../../pages/DashboardPage/modules/OrderHeading';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const currentSelectedOrder = useSelector(
    (state) => state.orders.currentSelectedOrder
  );

  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  const matches = useMediaQuery('(min-width:800px)');

  useEffect(() => {
    if (
      language.includes(window.localStorage.i18nextLng.split('-')[0]) ||
      window.localStorage.i18nextLng.split('-')[0] === 'en'
    ) {
      i18n.changeLanguage(window.localStorage.i18nextLng.split('-')[0]);
    } else {
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line
  }, []);

  const selectedOrder = useSelector((state) => {
    switch (currentSelectedOrder && currentSelectedOrder.type) {
      case 'delivery': {
        const index = state.orders.deliveryOrders.findIndex(
          (a) => a._id === currentSelectedOrder._id
        );
        if (index > -1) {
          return state.orders.deliveryOrders[index];
        } else {
          return undefined;
        }
      }
      default:
        return undefined;
    }
  });

  return (
    <div className={classes.root}>
      {!matches && currentSelectedOrder && currentSelectedOrder.type ? (
        <>
          <div style={{marginLeft: 12, marginRight: 12, width: '100%'}}>
            {selectedOrder && <OrderHeading selectedOrder={selectedOrder} enableCloseOrder />}
          </div>
        </>
      ) : (
        <>
          <div className={classes.leftDiv}>
            <div className={classes.flexBox}>
              <IconButton onClick={() => setMenuOpen(true)}>
                <MenuIcon />
              </IconButton>
              <div
                className={classes.titleText}
                onClick={() => {
                  if (dashboardAccess && dashboardAccess.includes('cashier')) {
                    if (
                      window.location.pathname &&
                      !window.location.pathname.includes('dashboard')
                    ) {
                      dispatch(push('/dashboard'));
                    }
                  }
                }}
              >
                {t('WTF Store')}
              </div>
            </div>
          </div>
          <div className={classes.rightDiv}>
            <HotMenu />
          </div>
        </>
      )}
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default Header;
