import {http} from '../../http';
import {setLoading} from '../miscActions';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';

export const getPreferences = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/preferences`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_PREFERENCES,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updatePreferences = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/preferences`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.INIT_PREFERENCES,
          payload: res.data,
        });
        await dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};
