import {Realtime} from 'ably/browser/static/ably-commonjs.js';
import {ABLY_KEY} from '../constants';
import {store} from '../../services/store';

window.Ably = new Realtime({
  key: ABLY_KEY,
  recover: function (_, cb) {
    cb(true);
  },
});

const selectRestaurant = (state) => {
  return state.auth.restData && state.auth.restData.store_id;
};

let currentRestaurant;

const handleRestaurant = () => {
  const prev = currentRestaurant;
  currentRestaurant = selectRestaurant(store.getState());
  if (currentRestaurant && prev !== currentRestaurant) {
    window.restaurantChannel = window.Ably.channels.get(currentRestaurant);
  }
};

store.subscribe(handleRestaurant);
