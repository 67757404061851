import React, {lazy, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import {lightTheme, darkTheme} from './utils/themes/theme';
import './App.scss';
import {Route, Switch} from 'react-router';
import {ToastContainer, toast} from 'react-toastify';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';

import suspenseHoc from './hoc/suspenseHoc';
import PrivateCashierAuthHoc from './hoc/PrivateCashierAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import Ably from './services/Ably';
import LoadingModal from './modules/LoadingModal';
import NotFound from './modules/NotFound';
const LandingPage = lazy(() => import('./pages/LandingPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const DashboardView = lazy(() => import('./mobileView/DashboardView'));
const ManageItemsPage = lazy(() => import('./pages/ManageItemsPage'));
const SalesReportPage = lazy(() => import('./pages/SalesReportPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
 
function App() {
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '2.03.03s');
      } else if (appVersion !== '2.03.03s') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '2.03.03s');
          window.location.reload();
        }, 3000);
      }
    };
  }, []);

  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  const matches = useMediaQuery('(min-width:800px)');
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <LoadingModal />
      <Switch>
        <PrivateCashierAuthHoc
          exact
          path="/dashboard"
          component={
            matches
              ? suspenseHoc(headerHoc(DashboardPage))
              : suspenseHoc(headerHoc(DashboardView))
          }
        />
        <PrivateCashierAuthHoc
          exact
          path="/manage-items"
          component={suspenseHoc(headerHoc(ManageItemsPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/sales-report"
          component={suspenseHoc(headerHoc(SalesReportPage))}
        />
        <PrivateCashierAuthHoc
          exact
          path="/profile"
          component={suspenseHoc(headerHoc(ProfilePage))}
        />
        <LandingAuthHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route component={NotFound} />
      </Switch>
      <Ably />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </ThemeProvider>
  );
}

export default App;
