import * as actionTypes from '../../actionTypes';

const initialState = {
  orderLoading: false,
  currentSelectedOrder: {_id: '', type: ''},
  deliveryOrders: [],
  qsrOrders: [],
  deliveryNPOrders: [],
};

export default function orderReducers(state = initialState, action) {
  switch (action.type) {
    case 'SET_CURRENT_SELECTED_ORDER': {
      return {
        ...state,
        currentSelectedOrder: action.payload,
      };
    }
    case 'ORDER_LOADING':
      return {
        ...state,
        orderLoading: action.payload,
      };
    case 'ADD_TO_DELIVERY_ORDERS': {
      const addDelivery = [...state.deliveryOrders];
      const addDeliveryIndex = addDelivery.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addDeliveryIndex > -1) {
        addDelivery[addDeliveryIndex] = action.payload;
      } else {
        addDelivery.push(action.payload);
      }
      return {
        ...state,
        deliveryOrders: addDelivery,
      };
    }
    case 'REMOVE_DELIVERY_ORDERS': {
      const removeDelivery = [...state.deliveryOrders];
      const removeDeliveryIndex = removeDelivery.findIndex(
        (a) => a._id === action.payload
      );
      if (removeDeliveryIndex > -1) {
        removeDelivery.splice(removeDeliveryIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        deliveryOrders: removeDelivery,
      };
    }
    case 'ADD_TO_NOT_POSSIBLE_DELIVERY': {
      const addDeliveryNP = [...state.deliveryNPOrders];
      const addDeliveryNPIndex = addDeliveryNP.findIndex(
        (a) => a._id === action.payload._id
      );
      if (addDeliveryNPIndex > -1) {
        addDeliveryNP[addDeliveryNPIndex] = action.payload;
      } else {
        addDeliveryNP.push(action.payload);
      }
      return {
        ...state,
        deliveryNPOrders: addDeliveryNP,
      };
    }
    case 'REMOVE_FROM_NOT_POSSIBLE_DELIVERY': {
      const removeDeliveryNP = [...state.deliveryNPOrders];
      const removeDeliveryNPIndex = removeDeliveryNP.findIndex(
        (a) => a._id === action.payload
      );
      if (removeDeliveryNPIndex > -1) {
        removeDeliveryNP.splice(removeDeliveryNPIndex, 1);
      }
      return {
        ...state,
        deliveryNPOrders: removeDeliveryNP,
      };
    }
    case 'ADD_TO_QSR_ORDERS': {
      const addQSR = [...state.qsrOrders];
      const addQSRIndex = addQSR.findIndex((a) => a._id === action.payload._id);
      if (addQSRIndex > -1) {
        addQSR[addQSRIndex] = action.payload;
      } else {
        addQSR.push(action.payload);
      }
      return {
        ...state,
        qsrOrders: addQSR,
      };
    }
    case 'REMOVE_QSR_ORDERS': {
      const removeQSR = [...state.qsrOrders];
      const removeQSRIndex = removeQSR.findIndex(
        (a) => a._id === action.payload
      );
      if (removeQSRIndex > -1) {
        removeQSR.splice(removeQSRIndex, 1);
      }
      return {
        ...state,
        currentSelectedOrder: {_id: '', type: ''},
        qsrOrders: removeQSR,
      };
    }
    case actionTypes.RESET_ORDERS:
      return {
        orderLoading: false,
        currentSelectedOrder: {_id: '', type: ''},
        deliveryOrders: [],
        qsrOrders: [],
        deliveryNPOrders: [],
      };
    case actionTypes.LOG_OUT:
      return {
        orderLoading: false,
        currentSelectedOrder: {_id: '', type: ''},
        deliveryOrders: [],
        qsrOrders: [],
        deliveryNPOrders: [],
      };
    default:
      return state;
  }
}
