import * as actionTypes from '../../actionTypes';

const initialState = {
  apiLoading: false,
  loginErr: false,
  restData: {},
  restaurant: {},
  wallet: {},
};

export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.REST_DATA:
      return {
        ...state,
        restData: action.payload,
      };
    case actionTypes.API_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.SET_RESTAURANT_DETAILS:
      return {
        ...state,
        restaurant: action.payload,
      };
    case actionTypes.SET_WALLET_DETAILS:
      return {
        ...state,
        wallet: action.payload,
      };
    case actionTypes.LOG_OUT:
      window.localStorage.removeItem('auth_token');
      window.localStorage.removeItem('refresh_token');
      return {
        apiLoading: false,
        loginErr: false,
        restData: {},
        restaurant: {},
        wallet: {},
      };
    default:
      return state;
  }
}
