import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function LandingAuthHoc({component: Component, ...rest}) {
  const restData = useSelector((state) => state.auth.restData);
  const token = restData && restData.token ? restData.token : '';
  const dashboardAccess = restData && restData.dashboard_access;
  return (
    <Route
      {...rest}
      render={(prop) =>
        token === '' ? (
          <Component {...prop} />
        ) : dashboardAccess.includes('cashier') ? (
          <Redirect to={'/dashboard'} />
        ) : (
          <Redirect to={'/qms'} />
        )
      }
    />
  );
}

export default LandingAuthHoc;
