import React from 'react';
import {useStyles} from '../styles';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

function LangSelector() {
  const classes = useStyles();
  const optedLang = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  const [t, i18n] = useTranslation();
  const handleLanguage = (newValue) => {
    i18n.changeLanguage(newValue);
  };

  const lang = [
    {
      label: t('English'),
      value: 'en',
      active: true,
    },
    {
      label: t('日本語'),
      value: 'ja',
      active: optedLang.includes('ja'),
    },
    {
      label: 'eesti keel',
      value: 'et',
      active: optedLang.includes('et'),
    },
    {
      label: 'Deutsche',
      value: 'de',
      active: optedLang.includes('de'),
    },
    {
      label: 'Español',
      value: 'es',
      active: optedLang.includes('es'),
    },
    {
      label: 'Bahasa Melayu',
      value: 'ms',
      active: optedLang.includes('ms'),
    },
    {
      label: 'Pilipino',
      value: 'fil',
      active: optedLang.includes('fil'),
    },
    {
      label: 'ไทย',
      value: 'th',
      active: optedLang.includes('th'),
    },
    {
      label: '中文',
      value: 'zh',
      active: optedLang.includes('zh'),
    },
    {
      label: 'русский',
      value: 'ru',
      active: optedLang.includes('ru'),
    },
  ];

  const activeLang = window.localStorage.getItem('i18nextLng');

  return (
    <div className={classes.langRoot}>
      <div className={classes.divider} />
      <div className={classes.langFlex}>
        {lang.map(
          (obj, index) =>
            obj.active && (
              <div
                className={`${classes.langText} ${
                  activeLang === obj.value && classes.langActiveText
                }`}
                key={index}
                onClick={() => handleLanguage(obj.value)}
              >
                {obj.label}
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default LangSelector;
