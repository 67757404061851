import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useStyles} from './styles';
import Typography from '@material-ui/core/Typography';
import {
  // printKOTandBillOrder,
  setCurrentSelectedOrder,
} from '../../../services/actions';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import BackButton from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';


function OrderHeading({
  selectedOrder,
  hide,
  enableCloseOrder,
  orderFor,
  orderForDinein,
}) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles()

  // const handleClick = () => {
  //   dispatch(printKOTandBillOrder(selectedOrder));
  // };

  const handleBack = () => {
    dispatch(setCurrentSelectedOrder());
  };

  return (
    <>
      <Toolbar style={{display: hide ? 'none' : 'flex'}}>
        {enableCloseOrder && (
          <div>
            <IconButton onClick={handleBack}>
              <BackButton />
            </IconButton>
          </div>
        )}
        <div style={{flex: 1}}>
          {orderFor || orderForDinein ? (
            <Typography
              variant="h6"
              component="h6"
              className={classes.takeawayOrderNumber}
            >
              {orderForDinein && `${t('Table No')} : `}
              <span
                style={{
                  color: '#FD2A2A',
                  fontSize: 22,
                  textTransform: 'uppercase',
                }}
              >
                {orderFor}
              </span>
            </Typography>
          ) : (
            <Typography
              variant="h6"
              component="h6"
              className={classes.takeawayOrderNumber}
            >
              {selectedOrder &&
                selectedOrder.__type === 'dinein' &&
                t('Table No')}
              &nbsp;
              <span style={{color: '#FD2A2A', fontSize: 22}}>
                {selectedOrder && selectedOrder.__type !== 'dinein'
                  ? selectedOrder.__type === 'group-delivery'
                    ? selectedOrder.order_group_number
                    : selectedOrder.order_number
                  : selectedOrder &&
                    selectedOrder.tables &&
                    selectedOrder.tables.length > 0 &&
                    selectedOrder.tables
                      .map((table) => table.table_no)
                      .toString()}
              </span>
            </Typography>
          )}
        </div>
        {/* {!orderFor && !orderForDinein && (
          <div>
            <Button
              size="small"
              fullWidth
              variant="outlined"
              color="primary"
              onClick={handleClick}
            >
              {t('Print')}
            </Button>
          </div>
        )} */}
      </Toolbar>
    </>
  );
}

export default OrderHeading;
