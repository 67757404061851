import React, {useEffect, useState} from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '../svgs/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchSource,
  updatePreferences,
} from '../../../services/actions';
import Notification from '../../Notification';
import NotificationIcon from '../svgs/NotificationIcon';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {useTranslation} from 'react-i18next';
// import NotificationAudioHigh from '../assets/notification-high.mp3';
// import NotificationAudioLow from '../assets/notification-low.mp3';
// import NotificationAudioMedium from '../assets/notification-medium.mp3';
import Button from '@material-ui/core/Button';
import CustomDeliveryIcon from '../../../pages/DashboardPage/svgs/CustomDeliveryIcon';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {S3_URL} from '../../../utils/constants';

function HotMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [ringNotification, setRingNotification] = useState(false);

  const [openNoti, setOpenNoti] = useState(false);
  const [audioErr, setAudioErr] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [preferences, setPreferences] = useState({});

  const restID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  const dashboardAccess = useSelector(
    (state) => state.auth.restData && state.auth.restData.dashboard_access
  );

  const notificationBadgeCount = useSelector((state) => {
    let count = 0;
    /** QSR PaymentList */
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((qsr) => {
        if (
          qsr.curr_state === 'payment-initalized' &&
          qsr.initialized_by === 'user'
        ) {
          count += 1;
        }
      });
    }
    /** Delivery AcceptList */
    if (state.orders.deliveryOrders.length > 0) {
      state.orders.deliveryOrders.forEach((delivery) => {
        if (delivery.next_states[0] === 'acknowledged') {
          count += 1;
        }
      });
    }
    if (state.orders.qsrOrders.length > 0) {
      state.orders.qsrOrders.forEach((qsr) => {
        if (qsr.next_states[0] === 'acknowledged') {
          count += 1;
        }
      });
    }
    if (
      state.orders.deliveryNPOrders &&
      state.orders.deliveryNPOrders.length > 0
    ) {
      count += state.orders.deliveryNPOrders.length;
    }
    return count;
  });

  useEffect(() => {
    if (notificationBadgeCount) {
      setRingNotification(true);
    } else {
      setRingNotification(false);
    }
  }, [notificationBadgeCount]);

  useEffect(() => {
    if (openNoti) {
      setRingNotification(false);
    }
  }, [openNoti]);

  const pref = useSelector((state) => state.pref.preferences);
  const orderTypes = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.order_types
      ? state.auth.restaurant.order_types
      : []
  );

  useEffect(() => {
    if (delivery || !delivery) {
      setPreferences(pref);
    }
  }, [pref, delivery]);

  const handleNotificationClick = () => {
    if (notificationBadgeCount > 0) {
      setOpenNoti(true);
    }
  };

  useEffect(() => {
    const noti = document.getElementById('notification-audio-wtf');
    if (ringNotification) {
      try {
        noti.play();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    } else {
      try {
        noti.pause();
      } catch (err) {
        console.log(err);
        setAudioErr(true);
      }
    }
  }, [ringNotification]);

  const handleDeliverySupport = () => {
    setDelivery(!delivery);
  };

  const handleRefresh = () => {
    dispatch(fetchSource(restID, dashboardAccess));
  };

  const icons = [
    {
      component: <CustomDeliveryIcon />,
      handleClick: handleDeliverySupport,
      active: orderTypes.includes('delivery'),
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <NotificationIcon />,
      handleClick: handleNotificationClick,
      count: notificationBadgeCount,
      active: true,
      visible: dashboardAccess && dashboardAccess.includes('cashier'),
    },
    {
      component: <RefreshIcon />,
      handleClick: handleRefresh,
      active: true,
      visible: true,
    },
  ];

  const NotificationAudio = useSelector((state) => {
    switch (
      state.pref.preferences &&
      state.pref.preferences.notification_volume
    ) {
      case 'low':
        return `${S3_URL}/notification/notification-low.mp3`;
      case 'medium':
        return `${S3_URL}/notification/notification-medium.mp3`;
      default:
        return `${S3_URL}/notification/notification-high.mp3`;
    }
  });

  return (
    <div className={classes.iconFlex}>
      <audio id="notification-audio-wtf" src={NotificationAudio} loop></audio>
      <Notification open={openNoti} handleClose={() => setOpenNoti(false)} />
      {icons
        .filter((a) => a.visible)
        .map(
          (icon, index) =>
            icon.active && (
              <IconButton key={index} onClick={icon.handleClick}>
                <Badge
                  className={classes.badge}
                  badgeContent={icon.count ? icon.count : 0}
                >
                  {icon.component}
                </Badge>
              </IconButton>
            )
        )}
      <Dialog
        open={audioErr}
        maxWidth={'sm'}
        onClose={() => setAudioErr(false)}
      >
        <DialogTitle>{t('New notification received')}</DialogTitle>
        <DialogContent>
          {t('Autoplay for notification sound is disabled, click ok to enable')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAudioErr(false)}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={delivery} width={'sm'} onClose={handleDeliverySupport}>
        <DialogTitle>{t('Delivery')}</DialogTitle>
        <DialogContent>
          <div className={classes.radioGroup}>
            <FormControlLabel
              control={
                <Switch
                  checked={preferences.delivery && preferences.delivery.active}
                  color="primary"
                  size="small"
                  disabled={
                    preferences.delivery &&
                    !(
                      preferences.delivery.max_radius ||
                      preferences.delivery.value_for_less_than_4km ||
                      preferences.delivery.value_for_more_than_4km
                    )
                  }
                  onChange={() => {
                    const temp = {...preferences};
                    temp['delivery']['active'] = !preferences['delivery'][
                      'active'
                    ];
                    dispatch(updatePreferences(temp));
                  }}
                />
              }
              label={t('Status')}
            />
          </div>
          {preferences.delivery &&
            !(
              preferences.delivery.max_radius ||
              preferences.delivery.value_for_less_than_4km ||
              preferences.delivery.value_for_more_than_4km
            ) && (
              <div className={classes.errorMessage}>
                *{' '}
                {t(
                  'Delivery preferences not initiated from merchant dashboard'
                )}
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeliverySupport()}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HotMenu;
